import React, { useState } from 'react';
import Table from './Structures/Table';
import { Link, useParams } from 'react-router-dom';

const LobbyistClients = ({ clients }) => {
  const [sortingField, setSortingField] = useState('RepresentationName');
  const [sortingDirection, setSortingDirection] = useState('asc');
  const { state } = useParams();

  // Sort clients based on sortingField and sortingDirection
  const sortClients = (clients) => {
    return [...clients].sort((a, b) => {
      const valueA = a[sortingField];
      const valueB = b[sortingField];
      if (valueA < valueB) {
        return sortingDirection === 'asc' ? -1 : 1;
      }
      if (valueA > valueB) {
        return sortingDirection === 'asc' ? 1 : -1;
      }
      return 0;
    });
  };

  // Handles changing the sorting field and direction
  const handleSort = (field) => {
    if (sortingField === field) {
      setSortingDirection(sortingDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setSortingField(field);
      setSortingDirection('asc');
    }
  };

  const headers = [
    <span onClick={() => handleSort('RepresentationName')} className="cursor-pointer">
      Client Name
    </span>,
    'Representation Dates',
    'Compensation Range',
  ];

  const rows = sortClients(clients).map((client) => [
    <Link to={`/${state}/company/${client.CompanyID}`} className="lobbyCompanyName">
      {client.RepresentationName}
    </Link>,
    <Link to={`/${state}/company/${client.CompanyID}`} className="lobbyCompanyName">
      {client.RepresentationDatesRegistered}
    </Link>,
    <Link to={`/${state}/company/${client.CompanyID}`} className="lobbyCompanyName">
      {client.PaymentRangeLabel}
    </Link>,
  ]);

  return (
    <div className="bg-[#F9FAFB] border border-[#E5E7EB] 2xl:p-8 xl:p-6 p-5 ">
      <div className="border-b border-[#E5E7EB] mb-5 pb-2.5">
        <h2 className="font-playfair text-[28px] text-darkblue font-medium leading-8">Clients</h2>
      </div>
      {clients && clients.length > 0 ? (
        <Table headers={headers} rows={rows} />
      ) : (
        <p className="font-playfair text-[28px] text-darkblue font-medium leading-8">
          No clients found for this lobbyist.
        </p>
      )}
    </div>
  );
};

export default LobbyistClients;
