import axios from 'axios';
import { store } from './app/store';
import { reset as resetUser, setUser } from './features/user/userSlice';
import userService from './features/user/userService';

async function refreshToken(searchTerm) {
  const user = await userService.getUser(searchTerm);
  store.dispatch(setUser(user));
  return user.token;
}

axios.interceptors.request.use(
  (config) => {
    const user = JSON.parse(localStorage.getItem('user'));
    if (user?.token) {
      config.headers['Authorization'] = `${user.token}`;
    }

    const state = store.getState();
    const prefix = state.user?.state;

    if (
      prefix &&
      !config.url.includes(`/${prefix}/`) &&
      !config.url.includes(`/api/search`) &&
      !config.url.includes(`/video/clip`) &&
      !config.url.includes(`/video/get-video`)
    ) {
      const urlParts = config.url.split('/api/');
      config.url = `/api/${prefix}/${urlParts[1]}`;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;

    if (error.response && error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;

      try {
        const { user } = store.getState();

        if (user.searchTerm) {
          const newToken = await refreshToken(user.searchTerm);

          originalRequest.headers['Authorization'] = `${newToken}`;

          return axios(originalRequest);
        }
      } catch (refreshError) {
        store.dispatch(resetUser());
        localStorage.removeItem('user');
        return Promise.reject(refreshError);
      }
    } else {
      console.error('Response Error:', error);
      return Promise.reject(error);
    }
  }
);
