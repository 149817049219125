import React from 'react';
import SkeletonTableLoader from './SkeletonTableLoader';

const Table = ({ headers, rows, loading }) => {
  return (
    <table className="w-full rounded-xl">
      <thead>
        <tr className="bg-[#ECEFF4] w-full">
          {headers.map((header, index) => (
            <th key={index} className="py-3.5 pl-4 text-left whitespace-nowrap font-medium text-[#182158] capitalize">
              {header}
            </th>
          ))}
        </tr>
      </thead>
      {loading ? (
        <SkeletonTableLoader numCols={headers.length} />
      ) : (
        <tbody className="divide-y divide-[#F3F5F8]">
          {rows.map((row, index) => (
            <tr key={index} className="bg-white transition-all duration-500 hover:bg-gray-50">
              {row.map((cell, index) => (
                <td key={index} className="p-4 text-black font-normal">
                  {cell}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      )}
    </table>
  );
};

export default Table;
