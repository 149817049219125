import React from 'react';

const SimpleFooter = () => {
  return (
    <div className="bg-[#F9FAFB] border border-[#E5E7EB] p-4">
      <p className="text-sm font-normal text-black/50 text-center">
        Copyright © 2024. Texas Legislative Service. All Rights Reserved.
      </p>
    </div>
  );
};

export default SimpleFooter;
