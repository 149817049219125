import React, { forwardRef } from 'react';
import DatePicker from 'react-datepicker';
import { IoIosArrowDown } from 'react-icons/io';
import 'react-datepicker/dist/react-datepicker.css';

const ButtonDatePicker = ({ startDate, setStartDate }) => {
  const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
    <button
      className="w-full border border-[#E5E7EB] bg-[#F9FAFB] text-black text-base font-normal py-2 px-3.5 focus:outline-none flex items-center gap-10"
      onClick={onClick}
      ref={ref}
    >
      {!startDate ? 'Month' : value}
      <IoIosArrowDown />
    </button>
  ));

  return (
    <div className="w-fit flex justify-center items-center">
      <DatePicker
        selected={startDate}
        onChange={(date) => setStartDate(date)}
        dateFormat="MMM, yyyy"
        showMonthYearPicker
        customInput={<ExampleCustomInput />}
        maxDate={new Date()}
      />
    </div>
  );
};

export default ButtonDatePicker;
