import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { login } from '../features/user/userSlice';
import CustomInput from './Common/CustomInput';
import CustomButton from './Common/CustomButton';
import { ClipLoader } from 'react-spinners';

const Login = () => {
  const [auth, setAuth] = useState({ username: '', password: '' });
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isLoading, isError } = useSelector((state) => state.user);

  const handleSubmit = async () => {
    try {
      await dispatch(login(auth)).unwrap();
      const user = JSON.parse(localStorage.getItem('user'));
      if (user) {
        console.log('NAVIGATE DEL DIABLO');
        navigate(`/${user.state}/search`);
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="flex flex-col gap-5">
      <CustomInput
        label="Username"
        placeholder="username"
        onChange={(e) => setAuth({ ...auth, username: e.target.value })}
      />
      <CustomInput
        label="Password"
        placeholder="password"
        type="password"
        onChange={(e) => setAuth({ ...auth, password: e.target.value })}
      />
      {isError && <span className="text-red-500">Username or password wrong!</span>}
      <CustomButton onClick={handleSubmit} disabled={isLoading}>
        Log In
      </CustomButton>
      {isLoading && (
        <div className="w-full flex justify-center items-center mt-9">
          <ClipLoader color="#1DB0FF" size={50} />
        </div>
      )}
    </div>
  );
};

export default Login;
