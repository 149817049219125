import React from 'react';
import { motion } from 'framer-motion';

const SkeletonTableLoader = ({ numRows = 5, numCols = 5 }) => {
  return (
    <tbody className="divide-y divide-[#F3F5F8]">
      {Array(numRows)
        .fill('')
        .map((_, rowIndex) => (
          <motion.tr
            key={rowIndex}
            className="animate-pulse bg-gray-100"
            initial={{ opacity: 0.5 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.8, yoyo: Infinity }}
          >
            {Array(numCols)
              .fill('')
              .map((_, colIndex) => (
                <td key={colIndex} className="p-4">
                  <div className="h-4 bg-gray-300 rounded"></div>
                </td>
              ))}
          </motion.tr>
        ))}
    </tbody>
  );
};

export default SkeletonTableLoader;
