import React from 'react';
import { IoIosArrowBack } from 'react-icons/io';
import { IoIosArrowForward } from 'react-icons/io';
import { usePagination, DOTS } from '../../hooks/usePagination';

const PaginationNav = ({ selected, setSelected, totalPages, pageSize = 10 }) => {
  const paginationRange = usePagination({
    currentPage: selected,
    totalPages,
    pageSize,
  });

  const handleSelectPage = (page) => {
    setSelected(page);
  };

  const handleNextPage = () => {
    if (selected < totalPages) setSelected(selected + 1);
  };

  const handlePrevPage = () => {
    if (selected > 1) setSelected(selected - 1);
  };

  return (
    <div className="w-fit">
      <ul className="inline-flex -space-x-px text-sm text-gray-500 bg-transparent gap-2 w-fit">
        <li
          className="flex items-center justify-center px-3 h-8 leading-tight border border-gray-300 hover:bg-gray-100 hover:text-gray-700 bg-white cursor-pointer"
          onClick={handlePrevPage}
        >
          <IoIosArrowBack />
        </li>
        {paginationRange.map((pageNumber) => {
          if (pageNumber === DOTS) {
            return (
              <li
                className="flex items-center justify-center px-3 h-8 leading-tight border border-gray-300  bg-white"
                key={pageNumber}
              >
                &#8230;
              </li>
            );
          }
          return (
            <li
              className={`flex items-center justify-center px-3 h-8 leading-tight border border-gray-300 hover:bg-gray-100 cursor-pointer ${
                selected === pageNumber ? 'bg-gray-100' : 'bg-white'
              }`}
              onClick={() => handleSelectPage(pageNumber)}
              key={pageNumber}
            >
              {pageNumber}
            </li>
          );
        })}
        <li
          className="flex items-center justify-center px-3 h-8 leading-tight border border-gray-300 hover:bg-gray-100 hover:text-gray-700 bg-white cursor-pointer"
          onClick={handleNextPage}
        >
          <IoIosArrowForward />
        </li>
      </ul>
    </div>
  );
};

export default PaginationNav;
