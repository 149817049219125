import React from 'react';

const Dropdown = ({ id, options, title, label, onChange, value, disabled }) => {

  return (
    <div className={`${label ? 'my-[30px]' : ''} w-full`}>
      {label && (
        <div className="mb-[15px]">
          <label htmlFor={id} className="font-playfair text-base font-medium text-black">
            {label}:
          </label>
        </div>
      )}
      <select
        id={id}
        className=" border border-[#E5E7EB] bg-[#F9FAFB] text-black text-base font-normal block w-full py-2 px-4 pe-9 focus:outline-none"
        onChange={onChange}
        value={value}
        disabled={disabled}
      >
        <option value={''} disabled>
          {title}
        </option>
        {options?.map((item, i) => (
          <>
            {item?.value ? (
              <option value={item.value} key={i + item.value}>
                {item.label}
              </option>
            ) : (
              <option value={item} key={i + item.value}>
                {item}
              </option>
            )}
          </>
        ))}
      </select>
    </div>
  );
};

export default Dropdown;
