import axios from 'axios';

const getSearchData = async () => {
  const response = await axios.get(`/api/lobby`);

  return response.data;
};

const getLobbyistById = async (id) => {
  const response = await axios.get(`/api/lobby/lobbyist/${id}`);

  console.log(response);

  return response.data;
};

const getCompanyById = async (id) => {
  const response = await axios.get(`/api/lobby/company/${id}`);

  console.log(response);

  return response.data;
};

const lobbyistService = { getSearchData, getLobbyistById, getCompanyById };

export default lobbyistService;
