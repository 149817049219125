import { configureStore } from '@reduxjs/toolkit';
import billReducer from '../features/bills/billSlice';
import lobbyReducer from '../features/lobby/lobbySlice';
import videoReducer from '../features/video/videoSlice';
import userReducer from '../features/user/userSlice';

export const store = configureStore({
  reducer: {
    bill: billReducer,
    lobby: lobbyReducer,
    video: videoReducer,
    user: userReducer,
  },
});
