import React from 'react';

const CustomButton = ({
  type = 'button',
  onClick,
  children,
  className = '',
  bgColor = '#83B2FA',
  hoverColor = '#679fe4', // Color de hover adicional
  fullWidth = false,
  ...props
}) => {
  return (
    <button
      type={type}
      onClick={onClick}
      style={{
        backgroundColor: bgColor,
        transition: 'background-color 0.3s',
      }}
      className={`py-3 px-8 text-base font-semibold text-white focus:outline-none ${
        fullWidth ? 'w-full' : ''
      } ${className}`}
      onMouseEnter={(e) => (e.currentTarget.style.backgroundColor = hoverColor)}
      onMouseLeave={(e) => (e.currentTarget.style.backgroundColor = bgColor)}
      {...props}
    >
      {children}
    </button>
  );
};

export default CustomButton;
