import React, { useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import Hls from 'hls.js';
// Store
import { useSelector, useDispatch } from 'react-redux';
import { getVideoInfo } from '../../features/video/videoSlice';
// Components
import { ChatBox, TransciptContainer } from '../../components/videoIndex';
// Spinner
import MainLoader from '../../components/Structures/MainLoader';

const Video = () => {
  const { videoId, state } = useParams();
  const dispatch = useDispatch();
  const videoRef = useRef(null);
  const hlsRef = useRef(null); // Reference for hls.js instance

  const { video, isLoading } = useSelector((state) => state.video);

  useEffect(() => {
    if (videoId) {
      dispatch(getVideoInfo({ videoId, state }));
    }
  }, [videoId, dispatch]);

  useEffect(() => {
    if (video.Link) {
      console.log(video.Link);

      const isHlsStream = video.Link.includes('.m3u8');
      const isMp4 = video.Link.includes('.mp4');

      if (isHlsStream && Hls.isSupported()) {
        hlsRef.current = new Hls();
        hlsRef.current.loadSource(video.Link);
        hlsRef.current.attachMedia(videoRef.current);
      } else if (isMp4 || videoRef.current.canPlayType('application/vnd.apple.mpegurl')) {
        videoRef.current.src = video.Link;
      }
    }

    return () => {
      if (hlsRef.current) {
        hlsRef.current.destroy();
      }
    };
  }, [video]);

  const onGoToTimeStamp = (timeInSeconds) => {
    videoRef.current.currentTime = timeInSeconds / 10;
    videoRef.current.play();
  };

  if (isLoading) return <MainLoader />;

  return (
    <div className="w-full flex gap-5">
      {video && (
        <>
          <div className="w-[40%] h-full overflow-hidden">
            <div className="py-[15px] px-5 border border-[#E5E7EB] bg-[#F9FAFB] mb-4">
              <div className="border-b border-[#E5E7EB] mb-5 pb-2.5">
                <span className="font-playfair text-[28px] capitalize text-darkblue font-medium leading-8">
                  Recording: <strong>{video.Committee}</strong>
                </span>
              </div>
              <div className="w-full flex justify-center">
                <video className="w-full" ref={videoRef} title="video" controls />
              </div>
            </div>
            <ChatBox videoId={videoId} />
          </div>
          <TransciptContainer transcript={video?.transcript} onGoToTimeStamp={onGoToTimeStamp} />
        </>
      )}
    </div>
  );
};

export default Video;
