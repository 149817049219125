export function isToday(date) {
  const givenDate = new Date(date).toISOString().slice(0, 10);
  const today = new Date().toISOString().slice(0, 10);

  return givenDate === today;
}

export function formatTime(seconds) {
  const hours = Math.floor(seconds / 3600);
  const remainingSeconds = seconds % 3600;
  const minutes = Math.floor(remainingSeconds / 60);

  if (hours > 0) {
    return `${hours}hr ${minutes}m`;
  } else {
    return `${minutes}m`;
  }
}

export const getBaseURL = () => {
  const { protocol, hostname, port } = window.location;
  return `${protocol}//${hostname}${port ? `:${port}` : ''}`;
};

export function formatBillNumber(billNumber) {
  // Match the letters and the numbers separately
  const match = billNumber.match(/^([A-Z]{2,3})(\d+)$/);

  if (!match) {
    throw new Error('Invalid bill number format');
  }

  const letters = match[1];
  const number = match[2];

  const paddedNumber = number.padStart(7 - letters.length, '0');

  return `${letters}${paddedNumber}`.toUpperCase();
}
