import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import lobbyService from './lobbyService';

const initialState = {
  lobby: [], // Use the matches data from localStorage, if it exists
  company: {},
  lobbyist: {},
  isLoading: false,
  isError: false,
  isSuccess: false,
  message: null,
};

export const getSearchData = createAsyncThunk('lobby/getSearchData', async (_, thunkAPI) => {
  try {
    const user = await lobbyService.getSearchData();
    return user;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const getLobbyistById = createAsyncThunk('lobby/getLobbyistById', async (id, thunkAPI) => {
  console.log(id);
  try {
    const user = await lobbyService.getLobbyistById(id);
    return user;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const getCompanyById = createAsyncThunk('lobby/getCompanyById', async (id, thunkAPI) => {
  console.log(id);
  try {
    const user = await lobbyService.getCompanyById(id);
    return user;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

const lobbySlice = createSlice({
  name: 'lobby',
  initialState,
  reducers: {
    reset: (state) => {
      state.lobby = [];
      state.isLoading = false;
      state.isError = false;
      state.isSuccess = false;
      state.message = null;
    },
    clearQueries: (state) => {
      state.queries = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getSearchData.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.isSuccess = false;
        state.message = null;
      })
      .addCase(getSearchData.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.lobby = action.payload;
        state.message = null;
      })
      .addCase(getSearchData.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
      })
      .addCase(getLobbyistById.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.isSuccess = false;
        state.message = null;
      })
      .addCase(getLobbyistById.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.lobbyist = action.payload;
        state.message = null;
      })
      .addCase(getLobbyistById.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
      })
      .addCase(getCompanyById.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.isSuccess = false;
        state.message = null;
      })
      .addCase(getCompanyById.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.company = action.payload;
        state.message = null;
      })
      .addCase(getCompanyById.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
      });
  },
});

export const { reset, clearQueries } = lobbySlice.actions;
export default lobbySlice.reducer;
