import axios from 'axios';

const getMatches = async ({ query, numResults, minPercentage, session }) => {
  const queryString = `query=${encodeURIComponent(
    query
  )}&numResults=${numResults}&minPercentage=${minPercentage}&session=${session}`;

  const response = await axios.get(`/api/bills?${queryString}`);
  return response.data;
};

const getSections = async ({ billId, session, version }) => {
  const response = await axios.get(`/api/bills/${billId}/${session}/${version}`);
  return response.data;
};

const getVersions = async (billId) => {
  const response = await axios.get(`/api/bills/versions/${billId}`);

  return response.data;
};

const getComparison = async ({ billId, versions }) => {
  // Assuming your API expects a POST request with a JSON body
  const url = `/api/bills/comparisons`;
  const response = await axios.post(url, {
    billId,
    versions, // This sends the versions array directly
  });
  return response.data;
};

const getQueryResponse = async ({ auth, query, billText, billId, mode, version }) => {
  const response = await axios.post(`/api/bills/query`, {
    auth,
    query,
    billText,
    billId,
    mode,
    version,
  });
  return response.data;
};

const getCompanies = async () => {
  // Assuming your API expects a POST request with a JSON body
  const url = `/api/bills/companies`;
  const response = await axios.get(url);
  return response.data;
};

const compileReport = async ({ company, billNumber, version }) => {
  const response = await axios.post(`/api/bills/compileReport`, {
    company,
    billNumber,
    version,
  });
  return response.data;
};

const clearQuery = async ({ auth, mode }) => {
  const response = await axios.post(`/api/bills/clear-cache`, {
    auth,
    mode,
  });
  return response.data;
};

const billService = {
  getMatches,
  getSections,
  getQueryResponse,
  getVersions,
  clearQuery,
  getComparison,
  getCompanies,
  compileReport,
};

export default billService;
