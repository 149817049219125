const convertMarkdownToHTML = (markdown) => {
  const headers = [
    {
      title: 'Company Sentiment Towards the Bill',
      id: 'company-sentiment',
      className: 'section-company-sentiment full-width',
      group: 0, // No group for full width
    },
    {
      title: 'Effective Date',
      id: 'effective-date',
      className: 'section-effective-date flex-item',
      group: 1,
    },
    {
      title: 'Area of Effect',
      id: 'area-of-effect',
      className: 'section-area-of-effect flex-item',
      group: 1,
    },
    {
      title: 'Who Benefits and How',
      id: 'who-benefits',
      className: 'section-who-benefits flex-item',
      group: 2,
    },
    {
      title: 'Who is Hurt and How',
      id: 'who-is-hurt',
      className: 'section-who-is-hurt flex-item',
      group: 2,
    },
    {
      title: 'Summary',
      id: 'summary-of-bill',
      className: 'section-summary full-width',
      group: 3, // No group for full width
    },
  ];

  let lines = markdown.split('\n');
  let htmlContent = '';
  let sections = [];
  let groupContent = {};
  let currentGroup = null;

  headers.forEach((header) => {
    let content = '';
    let recording = false;
    lines.forEach((line) => {
      if (line.startsWith(`### ${header.title}`)) {
        recording = true;
        content += `<div id="${header.id}" class="${header.className}" style="position: relative;"><h2>${header.title}</h2><RedoButton sectionId="${header.id}" />`;
      } else if (recording && line.startsWith('### ')) {
        recording = false; // Stop recording on new section start
        content += '</div>';
        sections.push({ content, group: header.group });
      } else if (recording) {
        content +=
          line
            .replace(/\*\*(.*)\*\*/gim, '<strong>$1</strong>') // Bold
            .replace(/\*(.*)\*/gim, '<em>$1</em>') // Italics
            .replace(/\[([^\[]+)\]\(([^\)]+)\)/gim, '<a href="$2">$1</a>') // Links
            .replace(/\n/g, '<br />') + '<br />';
      }
    });

    // Check if we ended the markdown without hitting another header
    if (recording) {
      content += '</div>';
      sections.push({ content, group: header.group });
    }
  });

  sections.forEach((section) => {
    if (section.group > 0) {
      if (!groupContent[section.group]) {
        groupContent[section.group] = `<div class="flex-container">`;
      }
      groupContent[section.group] += section.content;
    } else {
      htmlContent += section.content; // Add non-grouped sections directly
    }
  });

  // Close flex containers and append them in the correct order
  Object.keys(groupContent).forEach((key) => {
    htmlContent += groupContent[key] + '</div>';
  });

  return { __html: htmlContent };
};

export default convertMarkdownToHTML;
