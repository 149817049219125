import React, { useEffect } from 'react';
import { IoIosArrowDown } from 'react-icons/io';
import { IoIosArrowUp } from 'react-icons/io';

const SearchBar = ({ handleSearch, counter, location, onChangeLocation, size, search, setSearch, placeholder }) => {
  // const [search, setSearch] = useState(null);

  console.log(counter);
  useEffect(() => {
    const timer = setTimeout(() => {
      if (search !== null) handleSearch(search);
    }, 500);

    return () => clearTimeout(timer);
  }, [search]);

  const handleChange = (e) => {
    setSearch(e.target.value);
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleSearch(search);
    }
  };

  return (
    <div className="relative w-full">
      <input
        type="text"
        id="input-for-bill-no"
        placeholder={placeholder}
        className={`border border-[#E5E7EB] bg-white text-black text-base font-normal block py-2 px-3.5 focus:outline-none placeholder:text-black/30 w-full ${
          counter > 0 ? 'pl-[110px]' : ''
        }`}
        value={search || ''}
        onChange={handleChange}
        onKeyDown={handleKeyPress}
      />
      {counter > 0 && (
        <div className="z-20 absolute left-[10px] w-[90px] top-[5px] text-gray-400 flex justify-end bg-white">
          {counter > 1 && (
            <>
              <button className="hover:bg-white rounded-full" onClick={() => onChangeLocation('down')}>
                <IoIosArrowDown className="h-6 w-7" />
              </button>
              <button className="hover:bg-white rounded-full" onClick={() => onChangeLocation('up')}>
                <IoIosArrowUp className="h-6 w-7" />
              </button>
            </>
          )}
          <span className=" p-1 hover:bg-white ">{`${location}/${counter}`}</span>
        </div>
      )}
      {/* <button
        className="rounded-full p-1 hover:bg-white z-20 absolute right-1 top-[3px]"
        onClick={() => handleSearch(search)}
      >
        <IoSearchOutline className="w-6 h-6 text-gray-400 " />
      </button> */}
    </div>
  );
};

export default SearchBar;
