import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getCompanies, getVersions, compileReport } from '../features/bills/billSlice';
import NewSearchBar from '../components/NewSearchBar';
import convertMarkdownToHTML from '../components/QueryFuncs/Markdown';
import RedoButton from '../components/RedoButton';

const AIReport = () => {
  const dispatch = useDispatch();
  const companies = useSelector((state) => state.bill.companies);
  const versions = useSelector((state) => state.bill.versions);
  const report = useSelector((state) => state.bill.report);
  const [selectedCompany, setSelectedCompany] = useState('');
  const [billNumber, setBillNumber] = useState('');
  const [version, setVersion] = useState('');

  useEffect(() => {
    dispatch(getCompanies());
  }, [dispatch]);

  const handleCompanySelect = (company) => {
    setSelectedCompany(company);
  };

  const handleBillNumberChange = (event) => {
    setBillNumber(event.target.value);
  };

  const handleVersionChange = (event) => {
    setVersion(event.target.value);
  };

  const handleGetBillVers = () => {
    if (billNumber) {
      const billId = billNumber.replace(/\s/g, '');
      dispatch(getVersions(billId));
    }
  };

  const handleCompileReport = () => {
    dispatch(compileReport({ company: selectedCompany, billNumber, version }));
  };

  const isFormComplete = selectedCompany && billNumber && version;
  const nonNumberPattern = /\D.*\D/; // Looks for at least two non-digit characters anywhere
  const numberPattern = /\d/; // Looks for at least one digit

  const isBillNum = billNumber && billNumber.match(nonNumberPattern) && billNumber.match(numberPattern);

  return (
    <div className="bodyBox reportBox">
      <div className="billQueryForm AIReportBox">
        <div className="company-search">
          <NewSearchBar data={companies} fields={['name']} label="Company" onSelect={handleCompanySelect} />
        </div>
        <div className="bill-number-input">
          <label htmlFor="billNumber">Bill Number:</label>
          <input
            type="text"
            id="billNumber"
            value={billNumber}
            onChange={handleBillNumberChange}
            placeholder="Enter bill number"
          />
        </div>
        <button className={`vCompBtn ${!isBillNum ? 'pulse' : ''}`} onClick={handleGetBillVers} disabled={!isBillNum}>
          Get Versions
        </button>
        <div className="version-input">
          <label htmlFor="version">Version:</label>
          <select
            id="version"
            value={version}
            onChange={handleVersionChange}
            disabled={!billNumber || !versions || versions.length === 0}
          >
            <option value="">Select Version</option>
            {billNumber &&
              versions &&
              versions.map((ver) => (
                <option key={ver} value={ver}>
                  {ver.toUpperCase()}
                </option>
              ))}
          </select>
        </div>
        <button className="submitQuery" onClick={handleCompileReport} disabled={!isFormComplete}>
          Compile Report
        </button>
      </div>
      <div className="responseBody">
        {report && <div className="report-output" dangerouslySetInnerHTML={convertMarkdownToHTML(report)} />}
      </div>
    </div>
  );
};

export default AIReport;
