import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getVersions, getComparison } from '../features/bills/billSlice';
import Dropdown from '../components/Structures/Dropdown';
import ThreeArrowsSVG from '../components/SVGs/ThreeArrowsSVG';
import MainLoader from '../components/Structures/MainLoader';
import { IoMdArrowBack } from 'react-icons/io';

const Compare = () => {
  const { billId, state } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { versions, comparison, isLoading: billLoading } = useSelector((state) => state.bill);
  const { session } = useSelector((state) => state.user);

  const [selectedVersion1, setSelectedVersion1] = useState('');
  const [selectedVersion2, setSelectedVersion2] = useState('');
  const [viewMode, setViewMode] = useState('modified');
  const [isLoading, setIsLoading] = useState(false);
  const [showChanges, setShowChanges] = useState(false);
  const [sideBySideChanges, setSideBySideChanges] = useState(false);
  const [originalWithHighlights, setOriginalWithHighlights] = useState('');
  const [modifiedWithHighlights, setModifiedWithHighlights] = useState('');
  const [paddedSections1, setPaddedSections1] = useState([]);
  const [paddedSections2, setPaddedSections2] = useState([]);

  useEffect(() => {
    if (billId) {
      dispatch(getVersions(billId));
    }
  }, [billId]);

  useEffect(() => {
    setSelectedVersion1(versions.includes('FIL') ? 'FIL' : '');
    setViewMode(versions.includes('FIL') ? 'FIL' : 'modified');
  }, [versions]);

  useEffect(() => {
    const preparedChanges =
      comparison?.preparedData?.changes?.map((change) => {
        const addedTextMatch = change.summary.match(/Added text:\s*"([^"]+)"/);
        const removedTextMatch = change.summary.match(/Removed text:\s*"([^"]+)"/);

        if (addedTextMatch) {
          return { ...change, text: addedTextMatch[1], isAdded: true };
        } else if (removedTextMatch) {
          return { ...change, text: removedTextMatch[1], isAdded: false };
        }

        return change; // Return unchanged if the pattern doesn't match
      }) || [];

    const auxOriginalWithHighlights = applyHighlights(
      comparison?.preparedData?.originalTextBrief || '',
      preparedChanges.filter((change) => change.summary.toLowerCase().includes('removed')),
      false // Important: This marks the changes as "removed"
    );

    const auxModifiedWithHighlights = applyHighlights(
      comparison?.preparedData?.updatedTextBrief || '',
      preparedChanges.filter((change) => change.summary.toLowerCase().includes('added')),
      true // Important: This marks the changes as "added"
    );

    setOriginalWithHighlights(auxOriginalWithHighlights);
    setModifiedWithHighlights(auxModifiedWithHighlights);
  }, [comparison]);

  useEffect(() => {
    if (originalWithHighlights && modifiedWithHighlights) {
      const sections1 = splitTextIntoSections(originalWithHighlights);

      const sections2 = splitTextIntoSections(modifiedWithHighlights);

      const maxLength = Math.max(sections1.length, sections2.length);

      setPaddedSections1([...sections1, ...Array(maxLength - sections1.length).fill('')]);
      setPaddedSections2([...sections2, ...Array(maxLength - sections2.length).fill('')]);
    }
  }, [originalWithHighlights, modifiedWithHighlights]);

  const handleCompare = () => {
    if (selectedVersion1 && selectedVersion2) {
      setIsLoading(true); // Start loading
      dispatch(
        getComparison({
          billId,
          versions: [selectedVersion1, selectedVersion2],
        })
      ).finally(() => {
        setIsLoading(false); // Stop loading regardless of the result
      });
    }
  };

  const renderResponseWithBold = (response) => {
    // First, split the response into parts based on the pattern **bold** and numbers followed by periods
    const parts = response.split(/(\*\*[^*]+\*\*)|(\d+\.\s)/g); // This regex captures both patterns

    return parts.map((part, index) => {
      if (!part) return null; // Filter out any null or undefined parts that may result from split

      // Check for bold pattern
      if (part.startsWith('**') && part.endsWith('**')) {
        return <strong key={index}>{part.slice(2, -2)}</strong>; // Remove ** and wrap in <strong>
      }

      // Check for number followed by a period, indicating a new list item
      if (/\d+\.\s/.test(part)) {
        return (
          <React.Fragment key={index}>
            <br />
            <br />
            {part} {/* Add a line break before the number */}
          </React.Fragment>
        );
      }

      // Return other text as is
      return part;
    });
  };

  function escapeRegExp(string) {
    return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
  }

  function applyHighlights(text, changes, isAdded) {
    let highlightedText = text.replace(/^[\t ]+/gm, '');

    changes.forEach((change) => {
      if (change.text) {
        let textToHighlight = change.text;

        const escapedText = escapeRegExp(textToHighlight);

        const regexSafeText = escapedText.replace(/\s+/g, '(?:\\s|<(?:u|strike|strong|em)>)+');

        const regex = new RegExp(regexSafeText, 'gi');

        const className = isAdded ? 'added' : 'removed';

        const replacement = `<span class="${className}">$&</span>`;

        try {
          highlightedText = highlightedText.replace(regex, replacement);
        } catch (error) {
          console.error(`Error al aplicar highlight para: "${textToHighlight}"`, error);
        }
      }
    });

    return highlightedText;
  }

  function splitTextIntoSections(text) {
    const htmlOptionalPattern = '(?:^|\\n)(?:.*?<[^>]*>)*(?<!<u>)(?<!</u>)\\s*';
    const regex = new RegExp(htmlOptionalPattern + comparison.sectionsRegex, 'gm');

    const matches = [];
    let match;

    while ((match = regex.exec(text)) !== null) {
      matches.push({ index: match.index, match: match[0] });
    }

    if (matches.length === 0) {
      return [text];
    }

    const sections = [];
    let lastIndex = 0;

    if (matches[0].index > 0) {
      sections.push(text.slice(0, matches[0].index));
    }

    matches.forEach((match, index) => {
      const nextIndex = matches[index + 1]?.index || text.length;
      sections.push(text.slice(match.index, nextIndex));
      lastIndex = nextIndex;
    });

    function balanceHTML(section, previousSection) {
      const openTags = [];
      const tagPattern = /<\/?([a-z][a-z0-9]*)\b[^>]*>/gi;

      // Track tags in the current section
      const sectionTags = [...section.matchAll(tagPattern)];
      sectionTags.forEach((tag) => {
        if (tag[0].startsWith('</')) {
          openTags.pop();
        } else {
          openTags.push(tag[1]);
        }
      });

      // Track tags in the previous section
      const previousOpenTags = [];
      if (previousSection) {
        const prevTags = [...previousSection.matchAll(tagPattern)];
        prevTags.forEach((tag) => {
          if (tag[0].startsWith('</')) {
            previousOpenTags.pop();
          } else {
            previousOpenTags.push(tag[1]);
          }
        });
      }

      // Balance open tags between sections
      previousOpenTags.forEach((tag) => {
        previousSection += `</${tag}>`;
        section = `<${tag}>` + section;
      });

      return { balancedSection: section, previousSection };
    }

    return sections.reduce((acc, section, index) => {
      const previousSection = index > 0 ? acc[index - 1] : null;
      const balanced = balanceHTML(section, previousSection);
      acc.push(balanced.balancedSection);
      if (index > 0) acc[index - 1] = balanced.previousSection;
      return acc;
    }, []);
  }

  function addIndentation(text) {
    const indent = `&nbsp;`;

    let indentedText = text;

    indentedText = indentedText.replace(/(CHAPTER)/g, `<span class="indent">${indent.repeat(4)}</span>$1`);
    indentedText = indentedText.replace(
      /(Sec\.|SECTION|BE IT ENACTED|\([a-z]\))/g,
      `<span class="indent">${indent.repeat(6)}</span>$1`
    );
    indentedText = indentedText.replace(/(\(\d+\))/g, `<span class="indent">${indent.repeat(12)}</span>$1`);
    indentedText = indentedText.replace(/(\([A-Z]\))/g, `<span class="indent">${indent.repeat(18)}</span>$1`);
    indentedText = indentedText.replace(
      /\((i{1,3}|iv|v{1,3}|ix|x{1,3}|xl|l{1,3})\)/g,
      `<span class="indent">${indent.repeat(24)}</span>$1`
    );
    indentedText = indentedText.replace(/(AN ACT\n)/, `<div class="text-center">$1</div>`);

    indentedText = `<div class="w-fit">${indentedText}</div>`;
    return indentedText;
  }

  return isLoading || billLoading ? (
    <MainLoader />
  ) : (
    <>
      <div className="bg-[#F9FAFB] border border-[#E5E7EB] py-[15px] px-5 w-full mb-[20px]">
        <div className="border-b border-[#E5E7EB] mb-5 pb-2.5 flex items-center gap-8">
          <button
            className="hover:bg-darkblue-hover/15"
            onClick={() => navigate(`/${state}/bill/${billId}?session=${session}`)}
          >
            <IoMdArrowBack className="w-12 h-12 text-darkblue" />
          </button>
          <h2 id="Office-Information" className="font-playfair text-[28px] text-darkblue font-medium leading-8">
            Compare Versions
          </h2>
        </div>
        <div className="flex items-center gap-[15px]">
          <div className="hidden min-[1161px]:flex items-center gap-3 text-base font-medium text-black">
            Version
            <ThreeArrowsSVG />
          </div>
          <div className="flex flex-col lg:flex-row w-full items-center gap-[15px]">
            <Dropdown
              id="compareSelect 1"
              options={versions.filter((ver) => ver !== selectedVersion2)}
              title={'Version 1'}
              label=""
              value={selectedVersion1}
              onChange={(e) => setSelectedVersion1(e.target.value)}
            />
            <Dropdown
              id="compareSelect 2"
              options={versions.filter((ver) => ver !== selectedVersion1)}
              title={'Version 2'}
              label=""
              value={selectedVersion2}
              onChange={(e) => setSelectedVersion2(e.target.value)}
            />

            <div className="flex items-center gap-4">
              <button
                className="btn btn-enabled"
                onClick={handleCompare}
                disabled={!selectedVersion1 || !selectedVersion2}
              >
                Compare
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="tabs-container relative mb-[20px]">
        <ul className={`tabs-nav max-w-[calc(50%-12px)] gap-4 ${sideBySideChanges ? 'hidden' : 'flex'}`}>
          <li
            className="active"
            onClick={() => setViewMode(viewMode === selectedVersion1 ? selectedVersion2 : selectedVersion1)}
          >
            <div className="cursor-pointer">
              View: {viewMode === selectedVersion1 ? selectedVersion2 : selectedVersion1}
            </div>
          </li>
          <li className="active" onClick={() => setViewMode(viewMode === 'sideBySide' ? 'original' : 'sideBySide')}>
            <div className="cursor-pointer">
              {viewMode === 'sideBySide' ? 'View: AI Summary' : 'View: Side by Side'}
            </div>
          </li>
        </ul>

        <div className="tab-content">
          <div>
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-[20px]">
              {viewMode !== 'sideBySide' && !sideBySideChanges && (
                <div className={`bg-[#F9FAFB] border border-[#E5E7EB] py-[15px] px-5`}>
                  <div className="border-b border-[#E5E7EB] mb-4 pb-2.5">
                    <h2
                      id="Office-Information"
                      className="font-playfair text-[28px] text-darkblue font-medium leading-8"
                    >
                      {viewMode === selectedVersion1 ? selectedVersion1 + ' Version' : selectedVersion2 + ' Version'}
                    </h2>
                  </div>
                  <div
                    className="text-lg font-normal leading-[30px] text-black mb-[20px]"
                    dangerouslySetInnerHTML={{
                      __html:
                        viewMode === selectedVersion1
                          ? addIndentation(originalWithHighlights.replace(/\n/g, '<br />'))
                          : addIndentation(modifiedWithHighlights.replace(/\n/g, '<br />')),
                    }}
                  />
                </div>
              )}

              <div className={`tabs-container w-full ${viewMode === 'sideBySide' ? 'hidden' : 'block'}`}>
                <ul className="tabs-nav flex lg:absolute lg:top-0 lg:left-[50.5%] w-full max-w-[calc(50%-8px)] gap-4">
                  <li
                    className="active"
                    onClick={() => {
                      setShowChanges(!showChanges);
                      setSideBySideChanges(false);
                    }}
                  >
                    <div className="cursor-pointer">
                      View: {showChanges ? 'Impact of Changes' : 'Emnumetared Changes'}
                    </div>
                  </li>
                  <li
                    className="active"
                    onClick={() => {
                      setSideBySideChanges(!sideBySideChanges);
                      setShowChanges(false);
                    }}
                  >
                    <div className="cursor-pointer">{sideBySideChanges ? 'View: Versions' : 'View: Side by Side'}</div>
                  </li>
                </ul>

                <div
                  className={`bg-[#F9FAFB] border border-[#E5E7EB] py-[15px] px-5  ${
                    sideBySideChanges ? 'hidden' : 'block'
                  }`}
                >
                  <div className="border-b border-[#E5E7EB] mb-4 pb-2.5">
                    <h2 className="font-playfair text-[28px] text-darkblue font-medium leading-8">
                      {showChanges ? 'Enumerated Changes' : 'Impact of Changes'}
                    </h2>
                  </div>

                  {!showChanges && (
                    <>
                      {comparison?.aiResponse ? (
                        <p>{renderResponseWithBold(comparison.aiResponse)}</p>
                      ) : (
                        <p>No summary available.</p>
                      )}
                    </>
                  )}

                  {showChanges &&
                    comparison?.preparedData?.changes?.map((change, index) => (
                      <div className="mt-4" key={index}>
                        <p>
                          <strong>Change #{index + 1}</strong>
                        </p>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: change.summary,
                          }}
                        />
                      </div>
                    ))}
                </div>
              </div>
            </div>

            {sideBySideChanges && (
              <div className="grid grid-cols-1 lg:grid-cols-2 gap-[20px]">
                <div className="bg-[#F9FAFB] border border-[#E5E7EB] py-[15px] px-5 mt-12">
                  <div className="border-b border-[#E5E7EB] mb-4 pb-2.5 flex">
                    <h2 className="font-playfair text-[28px] text-darkblue font-medium leading-8 w-1/2 ml-5">
                      Impact of Changes
                    </h2>
                  </div>
                  {comparison?.aiResponse ? (
                    <p>{renderResponseWithBold(comparison.aiResponse)}</p>
                  ) : (
                    <p>No summary available.</p>
                  )}
                </div>
                <div className="bg-[#F9FAFB] border border-[#E5E7EB] py-[15px] px-5 mt-12">
                  <div className="border-b border-[#E5E7EB] mb-4 pb-2.5 flex">
                    <h2 className="font-playfair text-[28px] text-darkblue font-medium leading-8 w-1/2 ml-5">
                      Emnumerated Changes
                    </h2>
                  </div>
                  {comparison?.preparedData?.changes?.map((change, index) => (
                    <div className="mt-4" key={index}>
                      <p>
                        <strong>Change #{index + 1}</strong>
                      </p>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: change.summary,
                        }}
                      />
                    </div>
                  ))}
                </div>
              </div>
            )}

            {viewMode === 'sideBySide' && (
              <div className="bg-[#F9FAFB] border border-[#E5E7EB] py-[15px] px-5">
                <div className="border-b border-[#E5E7EB] mb-4 pb-2.5 flex">
                  <h2
                    id="Office-Information"
                    className="font-playfair text-[28px] text-darkblue font-medium leading-8 w-1/2 ml-5"
                  >
                    {selectedVersion1 + ' Version'}
                  </h2>
                  <h2
                    id="Office-Information"
                    className="font-playfair text-[28px] text-darkblue font-medium leading-8 w-1/2 ml-5"
                  >
                    {selectedVersion2 + ' Version'}
                  </h2>
                </div>
                <table className="w-full">
                  <tbody>
                    {paddedSections1.map((section1, index) => (
                      <tr key={index}>
                        <td
                          className="p-4 w-1/2 align-top"
                          dangerouslySetInnerHTML={{
                            __html: addIndentation(section1).replace(/\n/g, '<br />'),
                          }}
                        ></td>
                        <td
                          className="p-4 w-1/2 align-top"
                          dangerouslySetInnerHTML={{
                            __html: addIndentation(paddedSections2[index]).replace(/\n/g, '<br />'),
                          }}
                        ></td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </div>
        <div className="insufficient">
          The responses on this website are generated by artificial intelligence tools and do not reflect the views or
          opinions of our company. Please verify information independently.
        </div>
      </div>
    </>
  );
};

export default Compare;
