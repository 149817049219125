import txLogo from './assets/images/txLogo.png';
import laLogo from './assets/images/laLogo.png';
import arLogo from './assets/images/ARLogo.gif';
import msLogo from './assets/images/MSLogo.gif';
import okLogo from './assets/images/OKLogo.gif';
import ohLogo from './assets/images/ohLogo.png';

const stateConfig = {
  la: {
    website: 'http://www.legiscon.com/',
    logo: laLogo,
    bannerBlue: true,
    icon: '/icons/lafavicon.ico',
    tabTitle: 'Legiscon - AI',
  },
  tx: {
    website: 'https://www.telicon.com/',
    logo: txLogo,
    bannerBlue: true,
    icon: '/icons/txfavicon.ico',
    tabTitle: 'Telicon - AI',
  },
  ny: {
    website: 'http://www.nystatewatch.net/',
    logo: msLogo,
    bannerBlue: false,
    icon: '/icons/msfavicon.ico',
    tabTitle: 'NY StateWatch - AI',
  },
  ok: {
    website: 'http://www.legisok.net/',
    logo: okLogo,
    bannerBlue: false,
    icon: '/icons/okfavicon.ico',
    tabTitle: 'LegisOk - AI',
  },
  ms: {
    website: 'http://www.msstatewatch.net/',
    logo: msLogo,
    bannerBlue: false,
    icon: '/icons/msfavicon.ico',
    tabTitle: 'MS StateWatch - AI',
  },
  ar: {
    website: 'http://www.ardigest.net/',
    logo: arLogo,
    bannerBlue: false,
    icon: '/icons/msfavicon.ico',
    tabTitle: 'AR Digest - AI',
  },
  oh: {
    website: 'https://www.legislature.ohio.gov/',
    logo: ohLogo,
    bannerBlue: true,
    icon: '/icons/liqfavicon.ico',
    tabTitle: 'LegisIQ - AI',
    defaultVersion: 'FH00',
  },
  il: {
    website: "https://www.legislature.ohio.gov/",
    logo: ohLogo,
    bannerBlue: true,
    icon: "/icons/liqfavicon.ico",
    tabTitle: "LegisIQ - AI",
    defaultVersion: "INT",
  },
};

export default stateConfig;
