import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { getCompanyById } from '../features/lobby/lobbySlice';
import CompanyLobbyists from '../components/CompanyLobbyists';
import MainLoader from '../components/Structures/MainLoader';

const Company = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { isLoading, company } = useSelector((state) => state.lobby);
  const [lobbyistsWithPaymentRange, setLobbyistsWithPaymentRange] = useState([]);
  const [totalSpent, setTotalSpent] = useState({ low: 0, high: 0 });
  const [totalLobbyists, setTotalLobbyists] = useState(0); // Use state for totalLobbyists

  useEffect(() => {
    dispatch(getCompanyById(id));
  }, [dispatch, id]);

  useEffect(() => {
    const calculatePaymentRangesForLobbyists = () => {
      if (!company || !company.lobbyists) return;

      let totalLow = 0;
      let totalHigh = 0;
      let lobbyistsCount = 0; // Local variable to count lobbyists
      const enrichedLobbyists = company.lobbyists.map((lobbyist) => {
        const category = getPaymentCategory(lobbyist.RepresentationPaymentCategory);
        const low = category ? category.low : 0;
        const high = category ? category.high : 0;

        // Accumulate the totals
        totalLow += low;
        totalHigh += high;
        lobbyistsCount += 1; // Increment local count

        return {
          ...lobbyist,
          PaymentRange: { low, high },
          PaymentRangeLabel: category ? category.label : 'Unknown category',
        };
      });

      setLobbyistsWithPaymentRange(enrichedLobbyists);
      setTotalSpent({ low: totalLow, high: totalHigh });
      setTotalLobbyists(lobbyistsCount); // Update state with the count
    };

    const getPaymentCategory = (paymentCategory) => {
      const paymentCategories = {
        1: { low: 0, high: 24999, label: '$0 - $24,999' },
        2: { low: 25000, high: 49999, label: '$25,000 - $49,999' },
        3: { low: 50000, high: 99999, label: '$50,000 - $99,999' },
        4: { low: 100000, high: 249999, label: '$100,000 - $249,999' },
        5: { low: 250000, high: Infinity, label: '$250,000 or more' },
      };
      return paymentCategories[paymentCategory];
    };

    calculatePaymentRangesForLobbyists();
  }, [company]); // Make sure all dependencies are correctly listed

  if (isLoading) return <MainLoader />;

  return (
    <>
      <div className="bg-[#F9FAFB] border border-[#E5E7EB] 2xl:p-8 xl:p-6 p-5 mb-[20px]">
        <div className="border-b border-[#E5E7EB] mb-8 pb-8">
          {company.RepresentationName ? (
            <h2 className="font-playfair text-[28px] text-darkblue font-medium leading-8">
              {company.RepresentationName}
            </h2>
          ) : (
            <h2 className="font-playfair text-[28px] text-darkblue font-medium leading-8">No Company Found.</h2>
          )}
        </div>
        <div className="flex lg:flex-row flex-col items-center lg:justify-start justify-center gap-12">
          <div className="block flex-1">
            <h3 className="lg:text-left text-center  text-[22px] font-medium font-playfair mb-5">
              Total Lobbying Spend: ${totalSpent.low.toLocaleString()} to ${totalSpent.high.toLocaleString()}
            </h3>
            <h3 className="lg:text-left text-center  text-[22px] font-medium font-playfair mb-5">
              Total Lobbyists: {totalLobbyists}
            </h3>
            <div className="relative">
              <h4 className="lg:text-left text-center text-black text-[22px] font-medium font-playfair">
                {`${company.RepresentationStreet}, ${company.RepresentationCity}, ${company.RepresentationState} ${company.RepresentationZip}`}
              </h4>
              <p className="lg:text-left text-center text-black text-[22px] font-medium font-playfair">
                Branches Lobbied: {company.RepresentationBranchesLobbied}
              </p>
            </div>
          </div>
        </div>
      </div>

      {company && <CompanyLobbyists lobbyists={lobbyistsWithPaymentRange} />}
    </>
  );
};

export default Company;
